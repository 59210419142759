.footer {
	width: 100%;
	min-height: 50px;
	background-color: #354052;

	&__inner {
		display: flex;
	}

	&__items {
		& h2 {
			color: white;
		}

		display: flex;
		flex-direction: column;
	}

	&__items-center {
		margin: auto;
		display: flex;
		flex-direction: column;
	}


	&__item {
		color: white;
		margin-bottom: .5rem;

		& a {
			color: white;
		}
	}
}

@media(max-width: 768px) {
	.footer__items {
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.footer__items-center {
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.footer__inner {
		display: block;
	}
}
