.navbar__header {
    width: 100%;
    background: #434343;
}
// Header
.navbar__header-inner {
    display: flex;
    align-items: center;
    //height: 30px;
    padding: .2rem 0 .2rem 0;
    color: #afafaf;
}

.navbar__header-phone {
    margin-right: 2rem;

    .navbar__header-icons {
        width: 1rem;
        height: 1rem;
        margin-right: .5rem;
    }
}

.navbar__header-email {
    //margin-top: -3px;
    margin-right: 2rem;

    .navbar__header-icons {
        width: 1rem;
        height: 1rem;
        /*margin-left: 2rem;*/
        margin-right: .5rem;
    }
}

.navbar__header-social {

    .navbar__header-icons {
        width: 1.5rem !important;
        height: 1.5rem !important;
        margin-right: .5rem;
    }
}



.navbar {
    min-height: 60px;
    background: black !important;
}

.navbar__foundedgames-link {
    display: block;
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    text-align: justify;
    padding-bottom: 0.1rem;

    &:hover {
        text-decoration: none;
    }
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 1rem;
}

/*@media (min-width: 768px) {
    html {
        font-size: 1rem;
    }
}*/

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


// Navbar Dropdown
.dropdown__menu {
    background: rgba(53, 64, 82, 1);
}

.dropdown__menu::before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: white;
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: rgba(53, 64, 82, 1);
    transform: rotate(45deg);
}


@media (max-width: 991px) {

    .navbar__header-inner {
        display: grid;
    }

    .navbar__header-phone {
        grid-row: 1;
        grid-column: 1;
    }

    .navbar__header-email {
        grid-row: 2;
        grid-column: 1;
    }





    .container {
        display: grid !important;
        grid-template-columns: 100%;
    }

    a.navbar-brand {
        grid-row: 1;
        grid-column: 1;
        margin: 0 auto 15px;
        width: 120px !important;
    }

    div.dropdown {
        grid-row: 2;
        grid-column: 1;
        width: 100% !important;
    }

    .content {
        background-image: none;
        background-color: rgba(53, 64, 82, 1);
        height: auto;
        //background-attachment: unset;
    }
}
