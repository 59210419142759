// PaymentPage
.paymentpage__card {
    width: 100%;
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
    background: rgba(53,64,82,.8);
}

.paymentpage__image {
    width: 20%;
    max-width: 200px;
    min-width: 200px;
    overflow: hidden;
    border-radius: .5rem;

    img {
        width: 100%;
        border-radius: .5rem;
        margin-bottom: 1rem;
    }
}

.paymentpage__info {
    width: calc(100% - 220px);
    min-width: 300px;
    font-size: 14px;
    padding: 1rem;
    border: 1px solid white;
    margin-left: 20px;
}

.paymentpage__name {
    font-size: 1.2rem;
    font-weight: 700;
    color: white;
}

.paymentpage__form {
    width: 100%;
}

.paymentpage__label {
    display: inline;
    margin: 0;
    color: white;
}

.paymentpage__input {
    display: block;
    width: 100%;
    max-width: 300px;
    outline: none;
    padding: .3rem;
    margin: 0;
    border: 0;
    margin-bottom: 1rem;
}

.paymentpage__error {
    color: red;
}

.paymentpage__cost {
    font-size: 1.2rem;
    font-weight: 700;
    color: white;
}

.paymentpage__select {
    max-width: 300px;
    outline: none;
    font-size: 1rem;
    margin: 1rem 0;
}

.paymentpage__modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(53,64,82,.8);
    width: 100vw;
    height: 100vh;
    background-color: rgba(2, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.paymentpage__notes {
    color: white;
    width: 100%;
}
