.content {
  max-height: calc(100vh - 111px);
  min-height: calc(100vh - 111px);
  overflow: auto;
  flex: 1;
  padding: 1rem;
  background: url('https://cdn.pay2play.az/img/background.webp') center ;
  background-size: contain;
  
  @include media-breakpoint-down(sm) {
    width: 100vw;
    max-width: 100vw;
  }
}

.content__inner {
  min-height: 100vh;
}
  
@media(max-width: 768px){
  .content {
    padding: .5rem;
  }
  
  .content__inner {
    padding-bottom: 160px;
  }
}