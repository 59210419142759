// Games Group Grid
.games-group__card-grid {
    width: 100%;
    height: 100%;
    display: grid;
    gap: .5rem .25rem;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-auto-rows: auto;
}
// Games Group Grid end

// Games Group Card start
.games-group__card {
    display: flex;
    position: relative;
    max-width: 160px;
    cursor: pointer;
    margin: 0 auto;
    transition: all .3s linear 0;

    font-size: 1rem;
    padding: .3em;

    &:hover .games-group__card-name {
        opacity: 1;
        transition: all .35s linear 0;
    }
}

.games-group__card-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    transition: all .3s linear;

    font-size: inherit;
    border-radius: .5em;

}

.games-group__card-image {
    width: 100%;
    overflow: hidden;
    transition: all .3s ease 0s;
}

.games-group__card-name {
    position: absolute;
    color: black;
    font-size: 1rem;
    word-wrap: break-word;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background: #50a8e1;
    text-align: center;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all .2s linear .2s;
    font-style: italic;
    font-weight: 700;
}


// Adaptation
@media(max-width: 768px){
    .games-group__card-grid {
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    }
}

@media(max-width: 426px){
    .games-group__card-grid {
        gap: 0;
        grid-template-columns: repeat(3, minmax(120px, 1fr));
    }
}

@media(max-width: 414px){
    .games-group__card-grid {
        gap: 0;
        grid-template-columns: repeat(3, minmax(110px, 1fr));
    }

}


@media(max-width: 320px){
    .games-group__card-grid {
        gap: 0;
        grid-template-columns: repeat(3, minmax(85px, 1fr));
    }

}