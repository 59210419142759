$body-bg-color: #192c38;
$sidebar-bg-color: rgb(15, 33, 46);

// Theme colors
$blue: 			 #AFAFAF !default;
$indigo:	 	 #6610f2 !default;
$purple: 		 #6f42c1 !default;
$pink:		 	 #e83e8c !default;
$red: 			 #d9534f !default;
$orange: 		 #fd7e14 !default;
$yellow: 		 #f0ad4e !default;
$green: 		 #4BBF73 !default;
$teal: 			 #20c997 !default;
$cyan: 			 #1F9BCF !default;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$primary:       $blue !default;
$secondary:     $gray-700 !default;
$tertiary:      darken($blue, 10%) !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          #354052 !default;

// Border Radiuses
$border-radius-sm: .1rem;
$border-radius-md: .3rem;
$border-radius-lg: .5rem;

//Spacer
$spacer: 1rem;

// Transition
$transition: all .2s linear;
$transition-md: all .3s linear;
$transition-low: all .4s linear;